// 追踪链路查询字符串
export const TRACKER_LOGIN_QUERY_STR = "productBusinessSource=store";

// 子菜单列表
export const SUB_MENU_LIST = [
  {
    key: "destyomni",
    text: "desty",
    icon: "desty-omni-logo-v2",
  },
  {
    key: "destypage",
    text: "Page",
    icon: "desty-page-logo",
  },
  {
    key: "destystore",
    text: "Store",
    icon: "desty-store-logo",
  },
  {
    key: "destychat",
    text: "Chat",
    icon: "desty-chat-logo-v2",
  },
  {
    key: "destywholesale",
    text: "Wholesale",
    icon: "destywholesale-logo",
  },
];

export const SERVICE_LIST = [
  {
    key: "solutionfulfill",
    text: "Desty Fulfillment",
  },
];
